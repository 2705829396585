import * as storage from './storage';
import dlv from 'dlv';
import moment from 'moment';

export const NONE_ROLE = -1;

export const ROLE_INFO_OB = {
  tester: 0,
  mamanger: 1,
  admin: 2
};

const ROLE_INFO = [
  {
    label: 'admin',
    value: ROLE_INFO_OB.admin
  },
  {
    label: 'mamanger',
    value: ROLE_INFO_OB.mamanger
  },
  {
    label: 'tester',
    value: ROLE_INFO_OB.tester
  }
];

const ACCESS_TESTER = {
  dashboard: {
    test_failed: false,
    machine_not_completed: false,
    schedule: true,
    pie_chart: false
  },
  xray: {
    stp_test: true,
    safety_test: true
  },
  wtmd: {
    clearn_test: true,
    walk_test: true,
    safety_test: true
  },
  etd: {
    safety_test: true
  },
  hhmd: {
    safety_test: true
  },
  xrf: {
    safety_test: true
  },
  rameter: {
    safety_test: true
  },
  report: false,
  manage: false,
  profile: true
};

const ACCESS_ADMIN = {
  dashboard: {
    test_failed: true,
    machine_not_completed: true,
    schedule: false,
    pie_chart: true
  },
  xray: {
    stp_test: true,
    safety_test: true
  },
  wtmd: {
    clearn_test: true,
    walk_test: true,
    safety_test: true
  },
  etd: {
    safety_test: true
  },
  hhmd: {
    safety_test: true
  },
  xrf: {
    safety_test: true
  },
  rameter: {
    safety_test: true
  },
  report: true,
  manage: true,
  profile: true
};

export const getRole = () => {
  let role = NONE_ROLE;
  try {
    const roF = ROLE_INFO.filter((ro) => ro.value === storage.get(storage.personKey).role);
    if (roF.length > 0) role = roF[0].value;
  } catch (error) {
    return role;
  }
  return role;
};

export const getInvisible = (key: string) => {
  const role = getRole();
  let invisible = false;
  switch (role) {
    case ROLE_INFO_OB.admin:
      invisible = dlv(ACCESS_ADMIN, key);
      break;
    case ROLE_INFO_OB.mamanger:
      invisible = dlv(ACCESS_ADMIN, key);
      break;
    case ROLE_INFO_OB.tester:
      invisible = dlv(ACCESS_TESTER, key);
      break;
    default:
      invisible = false;
      break;
  }

  return invisible;
};

export const getWeekIndex = () => {
  var currentDate = moment();
  return currentDate.weekday();
};
