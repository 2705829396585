// import { AuthResponse, mockManagerDataAuth, mockTesterDataAuth, SignIn } from '../types/auth';
import * as storage from '../utils/storage';
import * as cookie from '../utils/cookie';
import * as http from '../utils/http';

// const urlApi = 'https://bkqm1.x-raytrainer.com';
// posti
const urlApi = 'https://bkqm2.x-raytrainer.com';

// const urlApi = 'http://localhost:8080';
// const urlApi = 'http://192.168.31.70:8080';
const personUrl = urlApi + '/xrayqmtest/api/login/person/';
const personresetUrl = urlApi + '/xrayqmtest/api/login/personreset/';
const logoutUrl = urlApi + '/xrayqmtest/api/login/person/logout/';

export const loginService = async (signIn: any) => {
  return http
    .fetch<any, { data: any }>(`${personUrl}`, {
      method: 'post',
      data: { ...signIn }
    })
    .then((data: any) => {
      return data;
    })
    .catch((error) => {
      console.log('error---', error);
      return error;
    });
};

export const resetService = async (signIn: any) => {
  return http
    .fetch<any, { data: any }>(`${personresetUrl}`, {
      method: 'post',
      data: { ...signIn }
    })
    .then((data: any) => {
      return data;
    })
    .catch((error) => {
      console.log('error---', error);
      return error;
    });
};

// export const passService = async () => {
//   return http
//     .fetch(personPassUrl, { method: 'get' })
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       return error;
//       // throw error;
//     });
// };

export const logoutService = async () => {
  return http
    .fetchWithAuth(`${logoutUrl}${storage.get(storage.personKey).personid}`, { method: 'get' })
    .then((response) => {
      cookie.remove(cookie.jwtCookieName);
      cookie.remove(cookie.jwtExpiryName);
      storage.remove(storage.personKey);
      return response;
    })
    .catch((error) => {
      cookie.remove(cookie.jwtCookieName);
      cookie.remove(cookie.jwtExpiryName);
      storage.remove(storage.personKey);
      return;
    });
};
