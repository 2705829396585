import { useRoutes } from 'react-router-dom';
import * as storage from '../utils/storage';

// routes
import MainRoutes from './MainRoutes';
import TesterRoutes from './TesterRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import { getRole, NONE_ROLE, ROLE_INFO_OB } from 'utils/common';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  if (getRole() === ROLE_INFO_OB.tester) return useRoutes([TesterRoutes, AuthenticationRoutes]);
  if (getRole() === ROLE_INFO_OB.mamanger) return useRoutes([MainRoutes, AuthenticationRoutes]);
  if (getRole() === ROLE_INFO_OB.admin) return useRoutes([MainRoutes, AuthenticationRoutes]);
  // if (getRole() === ROLE_INFO_OB.admin) return useRoutes([MainRoutes, AuthenticationRoutes]);
  // if (getRole() === NONE_ROLE) return useRoutes([AuthenticationRoutes]);
  return useRoutes([AuthenticationRoutes]);
}
