// assets
import { IconKey, IconArrowAutofitRight, IconInfoSquare } from '@tabler/icons';

// constant
const icons = {
  IconKey,
  IconArrowAutofitRight,
  IconInfoSquare
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const adminPages = {
  id: 'pages',
  // title: 'Pages',
  // caption: 'Pages Caption',
  type: 'group',
  children: [
    // {
    //   id: 'xray_machine',
    //   title: 'x-ray_machine',
    //   type: 'collapse',
    //   icon: icons.IconArrowAutofitRight,
    //   children: [
    //     {
    //       id: 'safety_test_01',
    //       title: 'safety_test',
    //       type: 'item',
    //       url: '/xray/safetytest'
    //     },
    //     {
    //       id: 'stp_test_01',
    //       title: 'stp_test',
    //       type: 'item',
    //       url: '/xray/spttest'
    //     }
    //   ]
    // },
    // {
    //   id: 'WTMD_machine',
    //   title: 'wtmd_machine',
    //   type: 'collapse',
    //   icon: icons.IconArrowAutofitRight,
    //   children: [
    //     {
    //       id: 'safety_test_02',
    //       title: 'safety_test',
    //       type: 'item',
    //       url: '/wtmd/safetytest'
    //     },
    //     {
    //       id: 'clean_test_verification_01',
    //       title: 'menu_clean_safety_test',
    //       type: 'item',
    //       url: '/wtmd/cleanTestVerification'
    //     },
    //     {
    //       id: 'walk_through_detection',
    //       title: 'menu_walk_test',
    //       type: 'item',
    //       url: '/wtmd/walkThroughDetection'
    //     }
    //   ]
    // },
    // {
    //   id: 'ETD_machine',
    //   title: 'etd_machine',
    //   type: 'collapse',
    //   icon: icons.IconArrowAutofitRight,
    //   children: [
    //     {
    //       id: 'safety_test_03',
    //       title: 'safety_test',
    //       type: 'item',
    //       url: '/etd/safetytest'
    //     }
    //   ]
    // },
    // {
    //   id: 'XRF_machine',
    //   title: 'xrf_machine',
    //   type: 'collapse',
    //   icon: icons.IconArrowAutofitRight,
    //   children: [
    //     {
    //       id: 'safety_test_04',
    //       title: 'safety_test',
    //       type: 'item',
    //       url: '/xrf/safetytest'
    //     }
    //   ]
    // },
    // {
    //   id: 'HHMD machine',
    //   title: 'hhmd_machine',
    //   type: 'collapse',
    //   icon: icons.IconArrowAutofitRight,
    //   children: [
    //     {
    //       id: 'safety_test_05',
    //       title: 'safety_test',
    //       type: 'item',
    //       url: '/hhmd/safetytest'
    //     }
    //   ]
    // },
    {
      id: 'Reports',
      title: 'reports',
      type: 'collapse',
      icon: icons.IconArrowAutofitRight,
      children: [
        {
          id: 'STP_test',
          title: 'stp_test',
          type: 'item',
          url: '/reports/stp'
        },
        {
          id: 'WTMD_test',
          title: 'wtmd_test',
          type: 'collapse',
          // url: '/reports/wtmd'
          children: [
            {
              id: 'clean_test_verification_01',
              title: 'menu_clean_safety_test',
              type: 'item',
              url: '/reports/wtmd/clean'
            },
            {
              id: 'walk_through_detection',
              title: 'menu_walk_test',
              type: 'item',
              url: '/reports/wtmd/walk'
            }
          ]
        },
        {
          id: 'safety_test_07',
          title: 'safety_test',
          type: 'collapse',
          children: [
            {
              id: 'X-RAY_01',
              title: 'X-RAY',
              type: 'item',
              url: '/reports/safety/xray'
            },
            // {
            //   id: 'WTMD_02',
            //   title: 'WTMD',
            //   type: 'item',
            //   url: '/reports/safety/wtmd'
            // },
            {
              id: 'RAMETER_06',
              title: 'RADMETER',
              type: 'item',
              url: '/reports/safety/rameter'
            }
            // {
            //   id: 'ETD_03',
            //   title: 'ETD',
            //   type: 'item',
            //   url: '/reports/safety/etd'
            // },
            // {
            //   id: 'XRF_04',
            //   title: 'XRF',
            //   type: 'item',
            //   url: '/reports/safety/xrf'
            // },
            // {
            //   id: 'HHMD_05',
            //   title: 'HHMD',
            //   type: 'item',
            //   url: '/reports/safety/hhmd'
            // }
          ]
        }
        // {
        //   id: 'clean_test_verification_02',
        //   title: 'menu_clean_safety_test',
        //   type: 'item',
        //   url: '/reports/safety/clean'
        // },
        // {
        //   id: 'walk_through_detection_02',
        //   title: 'menu_walk_test',
        //   type: 'item',
        //   url: '/reports/safety/walk'
        // }
      ]
    },
    {
      id: 'manage_systems',
      title: 'manage_systems',
      type: 'collapse',
      icon: icons.IconArrowAutofitRight,
      children: [
        {
          id: 'overview_id',
          title: 'manage_overview',
          type: 'item',
          url: 'manage/overview',
          breadcrumbs: false
        },
        {
          id: 'company_id',
          title: 'manage_companies',
          type: 'item',
          url: 'manage/companies',
          breadcrumbs: false
        },
        {
          id: 'devicde_id',
          title: 'manage_devices',
          type: 'item',
          url: 'manage/devices',
          breadcrumbs: false
        },
        {
          id: 'employee_id',
          title: 'manage_employees',
          type: 'item',
          url: 'manage/employees',
          breadcrumbs: false
        },
        {
          id: 'maintenances_id',
          title: 'manage_maintenances',
          type: 'item',
          url: 'manage/maintenances',
          breadcrumbs: false
        }
      ]
    }
    // {
    //   id: 'about',
    //   title: 'About',
    //   type: 'item',
    //   url: '/sample-page',
    //   icon: icons.IconInfoSquare,
    //   breadcrumbs: false
    // },
    // {
    //   id: 'authentication',
    //   title: 'Authentication',
    //   type: 'collapse',
    //   icon: icons.IconKey,

    //   children: [
    //     {
    //       id: 'login3',
    //       title: 'Login',
    //       type: 'item',
    //       url: '/login/login3',
    //       target: true
    //     }
    // {
    //   id: 'register3',
    //   title: 'Register',
    //   type: 'item',
    //   url: '/register/register3',
    //   target: true
    // }
    // ]
    // }
  ]
};

export default adminPages;
