import adminPages from './adminPages';
import dashboard from './dashboard';
// import utilities from './utilities';
import { getRole, ROLE_INFO_OB } from 'utils/common';
import testerPages from './testerPages';

// ==============================|| MENU ITEMS ||============================== //
const pages = getRole() === ROLE_INFO_OB.tester ? testerPages : adminPages;
const menuItems = {
  items: [dashboard, pages]
};

export default menuItems;
