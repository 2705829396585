import { lazy } from 'react';

// project imports
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'component/Loadable';

// login option 3 routing
import MainLayout from '../layout/MainLayout';
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const ForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword')));
const RenewPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/RenewPassword')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  // element: <AuthLogin3 />,
  children: [
    {
      path: '/login',
      element: <AuthLogin3 />
    },
    {
      path: '/forgotPassword',
      element: <ForgotPassword />
    },
    {
      path: '/tokenreset',
      element: <RenewPassword />
    }
    // {
    //   path: '/register/register3',
    //   element: <AuthRegister3 />
    // }
  ]
};

export default AuthenticationRoutes;
