// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { useEffect, useState } from 'react';
import { getRole, ROLE_INFO_OB } from 'utils/common';

import adminPages from '../../../../menu-items/adminPages';
import dashboard from '../../../../menu-items/dashboard';
import testerPages from '../../../../menu-items/testerPages';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const [navItems, setNavItems] = useState([]);
  useEffect(() => {
    const pages = getRole() === ROLE_INFO_OB.tester ? testerPages : adminPages;
    const menuItems = {
      items: [dashboard, pages]
    };

    // export default menuItems;

    const navs = menuItems.items.map((item) => {
      switch (item.type) {
        case 'group':
          return <NavGroup key={item.id} item={item} />;
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    });
    setNavItems(navs);
  }, []);
  return <>{navItems}</>;
};

export default MenuList;
